@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #000;
}
p {
  margin: 0;
  line-height: normal;
}
h2 {
  margin: 0;
  line-height: normal;
}
img {
  width: 100%;
}
a {
  text-decoration: none !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.c-flexcenter {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  justify-content: center;
}
.c-input {
  margin: 10px 0;
}
.col-md-6.c-input {
  margin: 0;
}
.col.c-input {
  margin: 0;
}
.c-input input {
  outline: none !important;
  -webkit-box-shadow: 0 0 5px #b0b0b0 !important;
  -moz-box-shadow: 0 0 5px #b0b0b0 !important;
  -ms-box-shadow: 0 0 5px #b0b0b0 !important;
  -o-box-shadow: 0 0 5px #b0b0b0 !important;
  box-shadow: 0 0 5px #b0b0b0 !important;
  border: 1px solid $DEFAULT !important;
  border-radius: 8px;
  height: 32px;
}
.c-input input.hp-error {
  border: 1px solid $COLOR !important;
}
.c-input textarea {
  resize: none;
  outline: none !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  border: 1px solid $DEFAULT !important;
  border-radius: 8px;
}
.c-input .c-label {
  font-size: 14px;
  color: #8d8d8d;
  font-weight: 300;
}
.c-input .c-button {
  min-width: 110px;
  margin-left: 8px;
  padding: 0;
  font-size: 11px;
}
.c-select .form-control {
  outline: none !important;
  -webkit-box-shadow: 0 0 5px #b0b0b0 !important;
  -moz-box-shadow: 0 0 5px #b0b0b0 !important;
  -ms-box-shadow: 0 0 5px #b0b0b0 !important;
  -o-box-shadow: 0 0 5px #b0b0b0 !important;
  box-shadow: 0 0 5px #b0b0b0 !important;
  border: 1px solid $DEFAULT !important;
  border-radius: 8px;
  padding: 0 8px;
  height: 32px;
}
.c-select .form-control[disabled] {
  background-color: #fff;
  opacity: 0.5;
}
.was-validated .form-control:valid {
  background-image: none;
  padding-right: inherit;
}
.c-button {
  background-color: transparent;
  border-radius: 5px;
  outline: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -ms-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important;
  padding: 7px 20px;
  min-width: 120px;
  line-height: normal;
  font-size: 14px;
  position: relative;
  height: 32px;
  cursor: pointer;
}
.c-button:disabled {
  cursor: default;
}
.c-button.c-button--default {
  background-color: transparent !important;
  border: 1px solid #0e216d;
  color: #0e216d;
}
.c-button.c-button--raised {
  border: 1px solid #0e216d;
  background-color: #0e216d;
  color: #fff !important;
  font-weight: 700;
}
.c-button.c-button--raised.c-secundary {
  border: 1px solid #2d6cb5;
  background-color: #2d6cb5;
}
.c-button.c-button--raised.c-pastel {
  border: 1px solid #84bade;
  background-color: #84bade;
}
.c-button.c-button--width {
  max-width: 100%;
  width: 100%;
}
.c-login {
  height: 100vh;
  width: 100%;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  justify-content: center;
}
.c-login__card {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -ms-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -o-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  width: 100%;
  max-width: 480px;
  border-radius: 10px;
  padding: 25px;
  background-color: #fff;
}
.c-login__card .c-card__title {
  text-align: center;
  margin-bottom: 10px;
}
.c-login__card .c-card__title img {
  max-width: 100px;
  margin-bottom: 20px;
}
.c-login__card .c-card__title p {
  color: #8d8d8d;
  font-weight: 700;
}
.c-login__card .c-card__title p strong {
  color: #0e216d;
}
.c-login__card .c-card__body {
  margin: auto;
  margin-bottom: 12px;
  height: 100%;
  max-height: 305px;
  overflow-y: scroll;
  padding: 10px;
}
.c-login__card .c-card__body .c-logo__image {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 100%;
  max-width: 250px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  -ms-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  -o-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  background-color: #fff;
  padding: 5px;
  margin: 10px auto;
  cursor: pointer;
}
.c-login__card .c-card__body .c-logo__image img {
  object-fit: cover;
}
.c-login__card .c-card__body .c-cancel {
  height: 30px;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #8d8d8d;
  font-weight: 600;
}
.c-login__card .c-card__body .c-digital {
  margin: 20px 0;
}
.c-input .c-digital {
  margin-bottom: 20px;
}
.c-login__card .c-card__body .c-digital p,
.c-input .c-digital p {
  font-size: 14px;
  color: #8d8d8d;
}
.c-login__card .c-card__body .c-digital ul,
.c-input .c-digital ul {
  margin: 10px 0;
  padding: 0;
}
.c-login__card .c-card__body .c-digital ul li,
.c-input .c-digital ul li {
  list-style: none;
}
.c-login__card .c-card__body .c-formato {
  margin: 5px 0;
}
.c-login__card .c-card__body .c-formato p {
  font-size: 13px;
}
.c-login__card .c-card__body .c-formato ul {
  padding-left: 20px;
  margin: 5px 0;
}
.c-login__card .c-card__body .c-formato ul li {
  font-size: 12px;
  line-height: normal;
  color: #8d8d8d;
}
.c-login__card .c-card__actions {
  margin-bottom: 20px;
  padding: 10px;
}
.c-login__card .c-card__actions .c-button {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
}
.c-login__card .c-card__actions .c-forget--password {
  width: 100%;
  color: #8d8d8d;
  font-weight: 700;
  display: block;
  text-align: center;
}
.c-login__card .c-card__actions.flex {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  jutify-content: center;
}
.c-login__card .c-card__footer {
  text-align: center;
  padding: 0 10px;
}
.c-login__card .c-card__footer p {
  font-size: 10px;
}
.c-title {
  margin: 20px 0;
}
.c-title h2 {
  font-size: 24px;
  line-height: normal;
  margin: 0;
  color: #000;
}
.c-table {
  width: 100%;
  border: none;
}
.c-table thead tr th,
.c-table tbody tr th,
.c-table thead tr td,
.c-table tbody tr td {
  font-size: 14px;
  padding: 5px 10px;
  cursor: pointer;
}
.c-table thead tr td,
.c-table tbody tr td {
  font-weight: 300;
}
.c-layout .c-header {
  background-color: #fff;
}
.c-layout .c-header .mdl-layout__drawer-button {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  justify-content: center;
}
.c-layout .c-header .mdl-layout__drawer-button i {
  color: #0e216d;
  font-size: 30px;
}
.c-layout .c-header .mdl-layout__header-row {
  justify-content: space-between;
}
.c-layout .c-header .mdl-layout__header-row img {
  max-width: 180px;
}
.c-layout .c-header .mdl-layout__header-row .c-header__title {
  margin-left: -25px;
}
.c-layout .c-header .mdl-layout__header-row .c-header__title img {
  max-width: 140px;
}
.c-layout .c-drawer {
  -webkit-transform: translateX(-320px);
  -moz-transform: translateX(-320px);
  -ms-transform: translateX(-320px);
  -o-transform: translateX(-320px);
  transform: translateX(-320px);
  background-color: #0e216d;
  border-color: #0e216d;
  width: 100%;
  max-width: 300px;
}
.c-layout .c-drawer.is-visible {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}
.c-layout .c-drawer .c-drawer__image {
  padding: 50px 20px;
  text-align: center;
}
.c-layout .c-drawer .c-drawer__image .c-image {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 100%;
  max-width: 250px;
  background-color: #fff;
  margin: auto;
  margin-bottom: 20px;
  border-radius: 10px;
}
.c-layout .c-drawer .c-drawer__image h2 {
  color: #fff;
  font-size: 20px;
}
.c-layout .c-drawer .mdl-navigation {
  padding-top: 0;
  border-top: 2px solid #84bade;
  border-bottom: 2px solid #84bade;
}
.c-layout .c-drawer .mdl-navigation a {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  color: #fff;
  text-decoration: none;
  padding: 0 30px;
  height: 55px;
}
.c-layout .c-drawer .mdl-navigation a:hover {
  background-color: #fff;
  color: #0e216d;
  border-top: 2px solid #fff;
}
.c-layout .c-drawer .mdl-navigation a .c-nav__icon {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  width: 100%;
  max-width: 40px;
}
.c-layout .c-drawer .mdl-navigation a .c-nav__icon i {
  font-size: 25px;
}
.c-layout .c-drawer .mdl-navigation a p {
  font-size: 15px;
}
.c-layout .c-drawer .mdl-navigation .c-nav__submenu {
  padding-left: 70px;
  border-top: 2px solid transparent;
  height: 40px;
}
.c-layout .c-drawer .mdl-navigation .c-nav__submenu .c-nav__icon {
  max-width: 30px;
}
.c-layout .c-drawer .mdl-navigation .c-nav__submenu .c-nav__icon i {
  font-size: 20px;
}
.c-layout .c-drawer .mdl-navigation .c-nav__submenu p {
  font-size: 14px;
}
.c-layout .mdl-layout__content {
  background-color: #efefef;
}
.c-layout .mdl-layout__content .c-page__title {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  height: 50px;
  background-color: #fff;
  padding: 0 40px;
}
.c-layout .mdl-layout__content .c-page__title h2 {
  color: #000;
  font-size: 16px;
}
.c-layout .mdl-layout__content .c-page__title i {
  color: #8d8d8d;
}
.c-layout .mdl-layout__content .c-page__title p {
  color: #8d8d8d;
  font-size: 16px;
}
.c-layout .mdl-layout__content .page-content {
  padding: 40px;
}
.c-layout .mdl-layout__content .page-content .c-card--container {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  -ms-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  -o-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  width: 100%;
  max-width: 950px;
  margin: auto;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
}
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-content__password {
  width: 100%;
}
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-content__password
  p {
  margin-bottom: 15px;
  text-align: justify;
}
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-content__password
  ul {
  padding-left: 25px;
  margin-bottom: 15px;
}
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-content__password
  ul
  li {
  font-size: 14px;
  line-height: normal;
}
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-content__password
  .c-input {
  width: 100%;
  max-width: 250px;
}
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-content__password
  .c-actions {
  margin: 25px 0;
}
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-content__password
  .c-actions
  .c-button {
  width: 100%;
  max-width: 250px;
}
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: flex-start;
}
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail.item {
  flex-direction: column;
  cursor: pointer;
}
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail.item
  .c-detail__item {
  margin-bottom: 30px;
  padding: 0;
}
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__item,
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__content {
  width: 100%;
  padding-right: 40px;
}
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__item
  h2,
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__content
  h2 {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;
}
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__item
  p,
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__content
  p {
  font-size: 14px;
  color: #8d8d8d;
  font-weight: 300;
}
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__item
  .c-detail__rutas,
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__content
  .c-detail__rutas,
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__item
  .c-detail__observaciones,
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__content
  .c-detail__observaciones {
  margin-top: 10px;
}
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__item
  .c-detail__rutas
  h3,
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__content
  .c-detail__rutas
  h3,
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__item
  .c-detail__observaciones
  h3,
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__content
  .c-detail__observaciones
  h3 {
  margin: 0;
  line-height: normal;
  font-size: 14px;
}
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__item
  .c-detail__rutas
  .c-rutas__item,
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__content
  .c-detail__rutas
  .c-rutas__item,
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__item
  .c-detail__observaciones
  .c-observaciones__item,
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__content
  .c-detail__observaciones
  .c-observaciones__item {
  margin: 10px 0;
}
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__item
  .c-detail__rutas
  .c-rutas__item
  .c-rutas__item--state,
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__content
  .c-detail__rutas
  .c-rutas__item
  .c-rutas__item--state,
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__item
  .c-detail__observaciones
  .c-observaciones__item
  .c-observaciones__item--user_name,
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__content
  .c-detail__observaciones
  .c-observaciones__item
  .c-observaciones__item--user_name {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  justify-content: space-between;
}
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__actions {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 240px;
}
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__actions
  .c-actions__button {
  width: 100%;
}
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-card__detail
  .c-detail__actions
  .c-actions__button
  .c-button {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  width: 100%;
}
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-empty__documents {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  height: 300px;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 225px;
  margin: auto;
  text-align: center;
}
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-empty__documents
  img {
  max-width: 60px;
  margin-bottom: 12px;
}
.c-layout
  .mdl-layout__content
  .page-content
  .c-card--container
  .c-empty__documents
  h2 {
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
}
.c-action--button {
  margin: 10px 0;
}
.mdl-layout--fixed-drawer.is-upgraded:not(.is-small-screen)
  > .mdl-layout__inner-container
  > .mdl-layout__header {
  margin-left: 300px;
  width: calc(100% - 300px);
}
.mdl-layout--fixed-drawer
  > .mdl-layout__inner-container
  > .mdl-layout__content {
  margin-left: 300px;
}
.c-loading {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}
.c-loading .c-loading__content {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 200px;
}
.c-loading .c-loading__content .c-loading__spinner {
  background-color: #fff;
  border-radius: 50%;
  padding: 6px;
  -webkit-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  -ms-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  -o-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14);
}
.c-loading .c-loading__content .spinner-border {
  color: #84bade;
}
.c-loading .c-loading__content p {
  font-size: 18px;
  color: #84bade;
  font-weight: 500;
  margin-left: 12px;
}
.modal .modal-content .modal-header {
  border: none;
  padding: 10px 15px;
}
.modal .modal-content .modal-header .modal-title {
  color: #2d6cb5;
  font-size: 16px;
  font-weight: 600;
}
.modal .modal-content .modal-body {
  padding: 5px 15px;
}
.modal .modal-content .modal-body canvas {
  width: 100% !important;
}
.modal .modal-content .modal-footer {
  border: none;
}
.modal .modal-content .modal-footer .c-button--default {
  color: #8d8d8d !important;
  border: none;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #0e216d;
  border-radius: 10px;
}
@media (max-width: 1024px) {
  .mdl-layout--fixed-drawer
    > .mdl-layout__inner-container
    > .mdl-layout__content {
    margin-left: 0;
  }
  .c-layout .mdl-layout__content .page-content {
    padding: 20px;
  }
}
@media (max-width: 768px) {
  .c-login__card {
    max-width: 100%;
    border-radius: 0;
    height: 100vh;
  }
  .c-layout .mdl-layout__content .c-page__title {
    padding: 0 20px;
  }
  .c-layout
    .mdl-layout__content
    .page-content
    .c-card--container
    .c-card__detail {
    display: block;
  }
  .c-layout
    .mdl-layout__content
    .page-content
    .c-card--container
    .c-card__detail
    .c-detail__content {
    padding: 0;
    margin-bottom: 15px;
  }
  .c-layout
    .mdl-layout__content
    .page-content
    .c-card--container
    .c-card__detail
    .c-detail__actions {
    max-width: 100%;
    flex-direction: row;
  }
  .c-layout
    .mdl-layout__content
    .page-content
    .c-card--container
    .c-card__detail
    .c-detail__actions
    .c-actions__button {
    width: 100%;
    max-width: 135px;
  }
  .c-layout
    .mdl-layout__content
    .page-content
    .c-card--container
    .c-card__detail
    .c-detail__actions
    .c-actions__code {
    width: 100%;
  }
}
.form-row {
  margin-top: 0.8rem;
}
.form-check {
  margin-top: 15px;
}
.c-card__body.validate {
  max-height: 350px;
}
.form-group {
  margin-bottom: 0;
}
.c-card__body li {
  line-height: 100%;
  margin-top: 8px;
}
