@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
.custom-div-input{
	margin:10px 0
}

.custom-input {
	outline:none !important;
	-webkit-box-shadow:0 0 5px #b0b0b0 !important;
	-moz-box-shadow:0 0 5px #b0b0b0 !important;
	-ms-box-shadow:0 0 5px #b0b0b0 !important;
	-o-box-shadow:0 0 5px #b0b0b0 !important;
	box-shadow:0 0 5px #b0b0b0 !important;
	border:1px solid $DEFAULT !important;
	border-radius:8px;height:40px;
	width: 100%;
}

i{
	position: absolute;
	top:10px;
	right: 15px;
}

.custom-input input.hp-error{
	border:1px solid $COLOR !important
}

.form-label {
    font-weight: bold;
    font-size: .85rem;
	margin-bottom: .2rem;
}
